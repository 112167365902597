const en = {
  confirmation: {
    close: "Close",
    delete: "Delete",
    accept: "Accept",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    confirmDeleteDialogStandardTitle: "Delete",
    confirmDeleteDialogStandardDescription: "Are you sure want to delete?",
  },

  daysOfTheWeek: {
    monday: "monday",
    tuesday: "tuesday",
    wednesday: "wednesday",
    thursday: "thursday",
    friday: "friday",
    saturday: "saturday",
    sunday: "sunday",
  },

  guards: {
    permissionDenied: {
      title: "Permission denied",
      body: "You do not have permission to access this page.",
    },
  },

  login: {
    title: "Hi, Welcome back",
    header: "Sign in to Nightz",
    no_account: "New user?",
    start_now: "Create an account",
    submit: "Login",
    forgot_password: "Forgot password?",
    fields: {
      emailOrPhone: "Email or phone",
      password: "Password",
    },
    validation: {
      emailOrPhone_required: "Email or phone is required",
      password_required: "Password is required",
    },
    errors: {
      IncorrectemailOrPhoneorpassword: "Incorrect credentials",
      Toomanyfailedattemptsofloginpleaseresetyourpassword:
        "Too many failed login attempts. Please reset your password!",
    },
    withSocials: {
      or: "OR",
    },
  },

  accountMenu: {
    message: "Hello! To continue, please log in or create a new account",
    login: "Login",
    register: "Register",
    logout: "Logout",
    home: "Home",
    tickets: "Tickets and Reservations",
    myCart: "My Cart",
    profile: "My Account",
    myDashboard: "My Dashboard",
  },

  registerPage: {
    pageTitle: "Register",
    pageMessage: "Register to Nightz",
    title: "Get started absolutely free.",
    subTitle: "Find parties everywhere.",
    accountType: "Account Type",
    userType: "User",
    companyType: "Company",
    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    password: "Password",
    register: "Register",
    message1: "By registering, I agree to Nightz",
    message2: "and",
    termsAndConditionsTitle: "Terms and Conditions",
    termsAndConditions: "Terms and Conditions",
    privacyPolicy: "Privacy Policy",
    alreadyAccount: "Already have an account?",
    login: "Login",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    emailAddressRequired: "Email address is required",
    passwordRequired: "Password is required",
    invalidEmail: "Email must be a valid email address",
    genericError: "A problem occurred! Please try again later.",
  },

  search: {
    notFound: "Not found",
    noResult: "No results found for",
    tryTypos: "Try checking for typos or using complete words.",
    enterKeywords: "Please enter keywords",
  },

  userProfile: {
    title: "Account",
    general: "General",
    notifications: "Notifications",
    socialLinks: "Social Links",
    changePassword: "Change Password",
  },

  userTicketsPage: {
    title: "My Activities",
    myAccount: "My Account",
    tickets: "Tickets and Reservations",
    upcomingEvents: "Upcoming Activities",
    upcomingBookings: "Upcoming Reservations",
    pastEvents: "Past Activities",
    pastBookings: "Past Reservations",
    noPastEventsTickets: "You have no reservations for past events",
    noUpcomingEventsTickets: "You have no reservations for upcoming events",
  },

  checkoutPage: {
    cartMaxQuantity: "You cannot add more than 10 items in cart",
  },

  accountGeneral: {
    firstName: "First Name",
    lastName: "Last Name",
    username: "User Name",
    phoneNumber: "Phone Number",
    country: "Country",
    city: "City",
    about: "About",
    saveChanges: "Save Changes",
    formatAllowed: "Allowed *.jpeg, *.jpg, *.png",
    maxSize: "Max size {{size}}",
    dashboard: "Dashboard",
    user: "User",
    accountSettings: "Account Settings",
    updateSuccess: "Update success!",
  },

  imageCropper: {
    save: "Save",
    remove: "Remove",
    cancel: "Cancel",
    editImage: "Edit Image",
  },

  listingList: {
    title: "Listings",
    subtitleNightz: "Entertainment, dining & relaxing",
    subtitleDayz: "Business, networking & learning",
    freeEntry: "Free Entry",
    allCountry: "All Country",
    startingFrom: "Starting from",
    seeMore: "See more",
    seeLess: "See less",
  },

  bookings: {
    error: "An error occurred, please try again",
    errorNoPermissionCompany: "A company is not allowed to make a reservation",
    successReservationMade:
      "A reservation request has been successfully made. Check your email or phone for more info!",
    errorNotEnoughPlaces:
      "A reservation can't be made because there are not enough places. " +
      "Please try and contact the organizer for further info",
    contactDetails: "Contact details",
    back: "Back",
    continue: "Continue",
    successCancel: "Booking cancel successfully!",
    cancelError: "There was an error, please try again.",
    numberOfGuest: "guest",
    numberOfGuests: "guests",
    yes: "Yes",
    no: "No",
    alertTitle: "Are you sure?",
    alertText: "Are you sure you want to cancel this reservation?",
    addTable: "Add table",
    title: "Reservations",
    on: "Bookings on",
    approve: "Approve",
    remove: "Remove",
    confirmed: "Confirmed",
    waiting_for_payment: "Waiting for payment",
    pending: "Pending",
    canceled: "Canceled",
    blocked: "Blocked",
    deleted_by_venue: "Deleted by venue",
    cancel_by_user: "Canceled by user",
    cancel_by_venue: "Canceled by venue",
    denied: "Denied",
    deleteError: "There was an error, please try again.",
    createError: "There was an error, please try again.",
    rescheduleError: "There was an error, please try again.",
    blockError: "There was an error, please try again.",
    noStatus: "No status",
    newReservation: "Reserve",
    blockInterval: "Block",
    tables: "Tables",
    area: "Area",
    guests: "Guests",
    time: "Time",
    date: "Date",
    save: "Save",
    delete: "Delete",
    deny: "Deny",
    cancel: "Cancel",
    fullName: "Full name",
    name: "Name",
    phoneNumber: "Phone number",
    email: "Email",
    contact: "Contact Details",
    length: "Length",
    cancelReservation: "Cancel Reservation",
    type: "Type...",
    tooManyGuests:
      "Warning! The capacity of the selected tables is not enough for the selected guests. The booking can still be saved",
    outsideBusinessHours: "The selected time is outside the business hours",
    startTimeIsInvalid: "The selected start time is invalid",
    endTimeIsInvalid: "The selected end time is invalid",
    closedDay: "The selected day is closed",
    approveReservation: "Approve Reservation?",
    giveUp: "Close",
    reservationApproved: "Reservation approved successfully!",
    noAvailableBookingCardOptions:
      "No options available. Please try a different combination.",
    loadingBookingCardOptions: "Please wait.",
    visit: "visit",
    formValidation: {
      statusRequired: "Status is required.",
      guestsRequired: "Number of guests is required.",
      guestsMin: "Number of guests must be bigger than 0.",
      dateRequired: "Date is required.",
      startTimeRequired: "Start time is required.",
      endTimeRequired: "End time is required.",
      areaRequired: "Area is required.",
      tablesRequired: "At least one table is required.",
      fullNameRequired: "Full name is required.",
      phoneNumber: "Phone number is required.",
      phoneNumberLength: "Please provide a correct phone number",
      emailRequired: "Email is required.",
      emailValid: "Please enter a valid email address.",
    },
    clientBookingValidation: {
      guestsRequired: "Number of guests is required.",
      dateRequired: "Date is required.",
      timeRequired: "Time is required.",
      areaRequired: "Area is required.",
      lengthRequired: "Length is required.",
    },
    bookingDetails: {
      title: "Reservation Details",
    },
    table: {
      name: "Name",
      status: "Status",
      guests: "Guests",
      area: "Area",
      tables: "Tables",
      time: "Booking date and time",
      phone: "Phone",
      email: "Email",
      id: "Reservation ID",
      reset: "Reset",
      reservationsTitle: "Reservations",
    },
    "howItWorks": "How it works",
    "holdingFee": "Holding Fee",
    "holdingFeeDisclaimer": "This venue uses a holding fee to confirm table reservations and avoid no-shows. This amount is going to be discounted from your bill.",
  },
  bookingWizard: {
    "title": "Choose your preference",
    tickets: "Tickets",
    booking: "Table",
    bookingMenu: "Menu",
  },

  checkout: {
    cart: {
      cart: "Cart",
      ticketsMaxQuantity: "You cannot add more tickets!",
      increase: "Quantity increased",
      decrease: "Quantity decreased",
      itemDelete: "Item deleted successfully!",
      itemDeleteError: "Item delete failed!",
      itemUpdateError: "Item update failed!",
    },
    billingAndAddress: "Billing & address",
    title: "Checkout",
    item: "item",
    items: "items",
    emptyCartTitle: "Cart is empty",
    emptyCartDescription: "Looks like you have no items in your shopping cart.",
    continueShopping: "Continue shopping",
    orderSummary: "Order summary",
    checkout: "Checkout",
    cartMaxQuantity: "You cannot add more than 10 items in cart!",
    acceptTermsAndConditions: "Please accept terms and conditions",
    subtotal: "Subtotal",
    discount: "Discount",
    processingFee: "Of which processing fee",
    vatIncluded: "VAT included if applicable.",
    discountCode: "Discount/Gift code",
    applyDiscount: "Apply",
    promoCodeApplied: "Discount/Gift code applied successfully.",
    product: "Product",
    seat: "Seats",
    price: "Price",
    quantity: "Quantity",
    totalPrice: "Total price",
    ticketType: "Ticket type",
    available: "Available",
    changeQty: "Modify",
    iReadAndAgree: "I read and I agree with the",
    billing: {
      addressTitle: "Billing address",
      title: "Please complete the following form with your data",
      validation: {
        firstName: "First name is required",
        lastName: "Last name is required",
        postCode: "Postal code is required",
        digits: "Postal code must contain only digits",
        phoneDigits: "Phone number must contain only digits",
        phoneMax: "Phone number must be a maximum of 15 digits",
        phoneMin: "Phone number must be a minimum of 7 digits",
        phone: "Phone number is required",
        email: "Email address is required",
        emailFormat: "Invalid email address",
        emailsMatching: "Emails must match",
        address: "Address is required",
        city: "City is required",
        state: "County is required",
      },
      firstName: "First name",
      lastName: "Last name",
      address: "Address",
      city: "Town / City",
      phone: "Phone number",
      email: "Email address",
      validateEmail: "Confirm email address",
      state: "County",
      country: "Country",
      postCode: "Postal code",
      back: "Back",
      continueToPayment: "Continue to payment",
    },
    completeOrder: "Complete order",
    edit: "Edit",
    emailDelivery: "Email delivery",
    deliveryOptions: "Delivery options",
    payment: {
      payment: "Payment",
      options: "Payment options",
      title1: "Pay with a new card",
      title2: "Pay with an existing card",
      description:
        "You will be redirected to Banca Transilvania website to safely complete your purchase.",
      cards: "Cards",
      expiration: "Expires in",
      newCard: "Add new card",
      deleteCard: "Delete selected card",
      loading: "Please don't close the window. Processing request.",
      cancel: "Cancel",
    },
    genericError: "A problem occurred! Please try again later.",
    errors: {
      noPromoCodeError: "This discount/gift code is not available!",
      noOnlySpacesError: "Input cannot contain only space",
    },
    success: {
      title: "Thank you for your purchase!",
      order1: "Order",
      order2: "has been placed.",
      mail: "Soon, you will receive an email with your purchased tickets.",
      download: "Alternatively, you can download them using the button below.",
      problems: "If you have any problems, do not hesitate to contact us.",
    },
    fail: {
      title: "OOPS!",
      message1: "It looks like there was an error placing your order.",
      message2: "Please try again later.",
      messageCode_104: "Restricted card (temporarily or permanently blocked)",
      messageCode_124: "Transaction cannot be authorised due to government, central bank or financial institution consent, laws or regulations",
      messageCode_320: "Inactive card. Please activate the card.",
      messageCode_801: "Issuer unavailable.",
      messageCode_803: "Card blocked. Contact issuing bank or retry transaction with another card.",
      messageCode_804: "Transaction not allowed. Please contact issuing bank or retry transaction with another card.",
      messageCode_805: "Transaction rejected.",
      messageCode_861: "Wrong card expiry date.",
      messageCode_871: "Wrong CVV.",
      messageCode_905: "Invalid card. It does not exist in the database.",
      messageCode_906: "Card expired.",
      messageCode_913: "Invalid transaction. Contact issuing bank or retry transaction with another card.",
      messageCode_914: "Invalid account. Please contact issuing bank.",
      messageCode_915: "Insufficient funds.",
      messageCode_917: "Transaction limit exceeded.",
      messageCode_952: "Suspected fraud.",
      messageCode_998: "Installment transactions are not allowed with this card. Please use a credit card issued by Banca Transilvania.",
      messageCode_341016: "3DS2 authentication is declined by Authentication Response (ARes) – issuer",
      messageCode_341017: "3DS2 authentication status in ARes is unknown - issuer",
      messageCode_341018: "3DS2 CReq cancelled - client",
      messageCode_341019: "3DS2 CReq failed - client/issuer",
      messageCode_341020: "3DS2 unknown status in RReq - issuer",
    },
    paymentProcessorMaintenance:
      "Payment processor is under maintenance, please try again later!",
  },

  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },

  passwordValidation: {
    uppercase: "At least one upper case letter",
    lowercase: "At least one lower case letter",
    number: "At least one digit",
    specialCharacter: "At least one symbol or special character",
    passwordLength: "At least 8 characters long",
  },

  changePassword: {
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm New Password",
    oldPasswordRequired: "Old Password is required",
    newPasswordRequired: "New Password is required",
    newPasswordLength: "Password must be at least 6 characters",
    passwordMismatch: "Passwords must match",
    success: "Update success!",
    errors: {
      invalidoldpassword: "Old Password is wrong!",
    },
  },

  resetPassword: {
    passwordRecoveryPrompt: "Forgot your password?",
    passwordRecoveryInstructions:
      "Please enter the email address or phone number associated with your account and we will send you a code to reset your password.",
    resetPasswordInstructions1:
      "We've sent a 6-digit confirmation code to your email.",
    resetPasswordInstructions2:
      "Please enter the code in below box to verify your email.",
    returnToSignIn: "Return to sign in",
    emailOrPhoneRequired: "Email or phone is required",
    emailOrPhone: "Email or phone",
    sendRequest: "Send Request",
    passwordCharLimit: "Password must be at least 6 characters",
    passwordRequired: "Password is required",
    confirmPassRequired: "Confirm password is required",
    passMismatch: "Passwords must match",
    password: "New Password",
    confirmPassword: "Confirm New Password",
    changePasswordSuccess: "Change password success!",
    updatePassword: "Update Password",
    requestSentSuccessfully: "Request sent successfully!",
    codeRequired: "Code is required",
    errors: {
      invalidcode: "The code is invalid!",
      usernotfound: "No user was found with that email address!",
    },
  },

  productPage: {
    getTicketsAndTable: "Tickets and table",
    getTicket: "Get Tickets",
    getVoucher: "Get Vouchers",
    back: "Back",
    freeAccess: "FREE ACCESS",
    noTicket: "You don’t need a ticket to attend",
    noPublishedTickets: "TICKETS ARE NOT FOR SALE YET",
    comeBack: "Try again later",
    addToCartSuccesss: "Tickets successfully added to cart!",
    comingSoon: "Coming Soon",
    soldOut: "Sold Out",
    tickets: "Tickets",
    vouchers: "Vouchers",
    organizer: "Organizer",
    gallery: "Gallery",
    overview: "Overview",
    priceRange: "Price Range",
    viewMenu: "View Menu",
    viewTickets: "View Tickets",
    viewBooking: "View Booking",
    reserveTable: "Reserve Table",
    dialogTitle: "You have a ticket to another event in cart",
    dialogAction: "Go to checkout",
    dialogContent:
      "If you want to buy a ticket to this event, you must first finish the checkout process for the one that is already in cart",
    typeOfTicket: "Type of Ticket",
    onlyinApp: "only available in app",
    redeem: "Redeem in app",
    typeOfTicketError: "Problem validating the ticket! Please reload the page.",
    addToCart: "Add To Cart",
    update: "Update",
    quantity: "Quantity",
    buyNow: "Buy",
    addedToCart: "Added to cart",
    chooseSeats: "Choose seats",
    chooseTickets: "Back to tickets",
    organizerEmail: "Organizer Email",
    organizerPhone: "Organizer Phone",
    DatabaseError:
      "Problem communicating with the database! Please try again later.",
    genericError: "A problem occurred! Please try again later.",
    continue: "Continue",
    itemsInYourCartTitle: "There are items in your cart",
    itemsInYourCartDescription:
      "To continue with your current selection, we'll have to remove your existing cart items.",
    cancel: "Cancel",
  },

  verifyCode: {
    errorVerifying: "Error while verifying!",
    successfulVerifying: "Verify success!",
    codeRequired: "Code is required",
    verify: "Verify",
  },

  landingPage: {
    title: "Your nightlife journey starts with",
    subTitle1: "Nightz is a dynamic and immersive social platform designed",
    subTitle2: "to elevate your event discovery and planning experience.",
    events: "Events",
    signIn: "Sign In",
    signUp: "Sign Up",
    home: "Home",
    allRightsReserved: "All rights reserved",
    madeBy: "made by",
    logout: "Logout",
    logoutError: "A problem occurred while logging out! Please try again.",
  },
  landingPageV2: {
    become_partner_button: "Get Started",
    become_partner_title: "BECOME A PARTNER",
    become_partner_description:
      " Are you a venue owner or event organizer? Create a business account and promote your activity in minutes.",
    trending_carousel_title: "TRENDING NOW",
    trending_carousel_description:
      "Explore the best activities recommended by Nightz.",
    trending_carousel_button: "Explore",
    friends_title: "FRIENDS",
    friends_description:
      "Nightz is the fastest & easiest way to hang out with your friends." +
      " We are all about real life socializing, that’s why we want you offline.\n",
    friends_button: "Hang Out",
    discover_title: "DISCOVER",
    discover_description:
      "We make the process of discovering the hottest events, coolest venues or finding something fun to do on" +
      "the weekend an enjoyable social experience.",
    discover_button: "Things to do",
    chat_and_plan_title: "CHAT & PLAN",
    chat_and_plan_description:
      "Talk to your friends, co-discover events and confirm attendance. " +
      "You can start your plan with an event or venue or start with a groupchat and decide what to do later",
    chat_and_plan_button: "Chat & Plan",
    stay_in_loop_title: "STAY IN THE LOOP",
    stay_in_loop_description_mobile:
      "Paint the town red, wherever you go. Find out who wants to hang out, and get curated recommendations based on location. Want to keep it quiet? Hide your profile.",
    stay_in_loop_description_web:
      "Paint the town red, wherever you go. Find out which friends want to hang out, if you are in the same city, and get your feed updated automatically with curated recommendations based on location. Want to keep it quiet? Hide your profile.",
    stay_in_loop_button: "Get going",
  },

  categories: {
    event: "Event",
    theatre: "Theatre",
    experience: "Experience",
    restaurant: "Restaurant",
    custom: "Custom",
    chill: "Chill",
    conference: "Conference",
    sportEvent: "Sport Event",
    landmark: "Landmark",
    bar: "Bar",
    coffeeShop: "Coffee Shop",
    headingSubcategoryNightz: "Choose one of the Nightz options",
    headingSubcategoryDayz: "Choose one of the Dayz options",
    headingCategory: "What activity does your activity fit in?",
    backToMyListings: "Back to My Listings",
  },

  platformSettings: {
    title: "Platform Settings",
    subtitle: "Admin Interface",
  },

  companiesList: {
    title: "Companies list",
    subtitle: "Admin Interface",
    view: "View",
    edit: "Switch for editing fields",
    save: "Save",
    download: "Download table",
    searchNamePlaceholder: "Search Owner name, email ore phone...",
    tableHeader: {
      name: "Name",
      emailAccount: "Email Account",
      phoneAccount: "Phone Account",
      company: "Company",
      cui: "TIN/FEIN",
      cuiPopover:
        "Tax Identification Number/Federal Employer Identification Number",
      documents: "Documents",
      status: "Status",
    },
    statusTypes: {
      pending: "Pending",
      banned: "Banned",
      approved: "Accepted",
      selfManaged: "Self managed",
      draft: "Draft",
    },
    tableCellPopover: {
      all: "All documents uploaded",
      mandatory: "Only the registration certificate and b2b Contract uploaded",
      notMandatory:
        "The registration certificate and b2b Contract have not been uploaded yet",
    },
    registerForm: {
      title: "Company account approval",
      description:
        "Check all the company information and set the ticketing commission according to the contract.",
      status: "Company status",
      commission: "Transaction commission",
    },
    errorMessages: {
      required: "This field is required",
      min: "Commission must be greater than or equal to 0",
      max: "Commission must be less than or equal to 100",
    },
  },

  transactionTab: {
    excelOptions: {
      ticketsOnly: "Only Tickets",
      ticketsOnlyFiltered: "Only Filtered Tickets",
      ordersOnly: "Only Transactions",
      ordersOnlyFiltered: "Only Filtered Transactions",
      ticketsAndOrders: "Transactions and Tickets",
      ticketsAndOrdersFiltered: "Filtered Transactions and Tickets",
    },
  },

  eventManager: {
    addModule: "Add Module",
    statusTypes: {
      created: "Created",
      draft: "Draft",
      ready: "Ready",
      pending: "Pending",
      ongoing: "Ongoing",
      expired: "Expired",
      deleted: "Deleted",
      cancelled: "Cancelled",
      waitingForPayment: "Waiting for payment",
    },
    title: {
      newEvent: "Create a New Listing",
      editEvent: "Edit",
    },
    modulesSummary: {
      settingsTitle: "Admin Settings",
      settingsDescription:
        "Control the the main characteristics of the event, such as category, tags, status and visibility.",
      durationTitle: "Duration",
      durationDescription:
        "Tell people when your event starts and ends or whether your services are available permanently.",
      generalDetailsTitle: "General Details",
      generalDetailsDescription:
        "Name your event or venue, set the main cover image and tell a story to your audience.",
      locationTitle: "Location",
      locationDescription:
        "Let people know where your event or venue is located, and get discovered by people in the area.",
      ratingTitle: "Rating",
      ratingDescription: "Set up other links associated with your listing",
      contactTitle: "Contact Details",
      contactDescription:
        "Set up your social links as well as methods people can get in touch with you.",
      imageGalleryTitle: "Image Gallery",
      imageGalleryDescription:
        "Create a visual impact by adding stunning pictures of your products or services.",
      ticketingTitle: "Ticketing",
      ticketingDescription:
        "Set up custom free or paid tickets, add promo codes and send invites for your event.",
      openingHoursTitle: "Opening Hours",
      openingHoursDescription:
        "Inform customers about the opening and closing time.",
      reservationsTitle: "Reservations",
      reservationsDescription:
        "Define bookable spaces within your venue and start managing reservations instantly.",
      reservationsMenuTitle: "Menu Reservations",
      reservationsMenuDescription:
        "Define menus for bookings",
    },
    warningDeleteModal: {
      standardWarningDeleteTitle: "Deleting Module",
      standardWarningDeleteMessage:
        "Are you sure do you want to delete this module and all of the data associated with it?",
    },
    areas: {
      nameLabel: "Name",
      priceLabel: "Price",
      tableTitle: "Areas",
      addArea: "Add Area",
      editOption: "Edit",
      deleteOption: "Delete",
      editModalNewAreaTitle: "Add new Area",
      editModalExistingAreaTitle: "Edit Area",
      editModalNameLabel: "Name of your area",
      editModalPriceLabel: "Price of the area",
      forbiddenDeleteAreaDialogTitle: "Error",
      forbiddenDeleteAreaDialogDescription:
        "This area can't be deleted as there are tables that belong to it. " +
        "Please first delete those tables and then you can proceed with deleting this area.",
    },
    spaces: {
      nameLabel: "Name",
      areaLabel: "Area",
      capacityLabel: "Capacity",
      tableTitle: "Tables",
      addSpace: "Add Table",
      editOption: "Edit",
      deleteOption: "Delete",
      editModalNewSpaceTitle: "Add a new Table",
      editModalExistingSpaceTitle: "Edit Table",
      editModalNameLabel: "Name of the Table",
      editModalCapacityLabel: "Capacity of the Table",
      editModalPriceLabel: "Price of the Table",
      noAreasModalTitle: "No area has been found",
      noAreasModalDescription:
        "No area has been created so that your table can belong to. Please first add at least one are.",
    },
    menus: {
      tableTitle: "Menus",
      nameLabel: "Name",
      categoryLabel: "Category",
      priceLabel: "Price",
      quantityLabel: "Quantity",
      addMenu: "Add Menu",
      editOption: "Edit",
      deleteOption: "Delete",
      editModalNewMenuTitle: "Add a new Menu",
      editModalExistingMenuTitle: "Edit Menu",
      editModalNameLabel: "Name",
      editModalCategoryLabel: "Category",
      editModalPriceLabel: "Price",
      editModalQuantityLabel: "Quantity",
      editModalMetricLabel: "Metric",
      category: {
        FOOD: 'Food',
        ALCOHOLIC_DRINK: 'Alcoholic drink',
        NON_ALCOHOLIC_DRINK: 'Non alcoholic drink',
      },
      quantityMetric: {
        GRAM: 'g (grams)',
        BOTTLE: 'bottle(s)',
      },
    },
    bookingSettings: {
      cardLabel: "Reservation Settings",
      bookingIntervalLabel: "Booking Interval",
      bookingDurationLabel: "Booking Duration (in minutes)",
      automaticConfirmationLabel: "Automatic Confirmation of a booking",
      holdingFeeLabel: "Holding fee when booking",
      bookingIntervalPart1: "Every",
      bookingIntervalPart2: "minutes",
    },
    embedIframe: {
      title: "Embed Iframe",
      descriptionPart1:
        "To implement the reservations system in your website, you can use the generator below, which will create an Iframe with the specified width and height values. If you need assistance, don't hesitate to ",
      descriptionPart2: "contact us",
      width: "Width (px)",
      height: "Height (px)",
      submit: "Generate",
      copyText: "Copy code",
      copySuccess: "Iframe copied to clipboard",
      copyFailure: "Failed to copy iframe to clipboard",
      theme: "Theme",
      darkLabel: "Dark",
      lightLabel: "Light",
    },
    closeCurrentDay: "Opened now!   Mark this day as closed.",
    openCurrentDay: "Closed now!   Mark this day as opened.",
    toggleModuleTrue: "ENABLED",
    toggleModuleFalse: "DISABLED",
    listings: "My listings",
    business: "My business",
    delete: "Delete",
    deleteMessage: "Are you sure you want to delete",
    items: "items",
    item: "item",
    edit: "Edit",
    duplicate: "Duplicate",
    selected: "selected",
    new: "New listing",
    clear: "Clear selection",
    entryType: "Type of the entry",
    free: "Free",
    ticket: "Ticket",
    ditlReport: "DITL Report",
    ditlReportQuestion: "Do you want to report info to DITL?",
    yes: "I want",
    no: "I don't want",
    subcategory: "Subcategory",
    bookLink: "Booking Link",
    menuLink: "Menu Link",
    noVisibleticketsError: "At least one ticket needs to be visible!",
    eventName: "Event name",
    description: "Description",
    writeSomethingAwesome: "Write something awesome...",
    nightzComission: "Nightz comission",
    nightzComissionText: "The commission that Nightz will take from the event",
    mainImage: "Main Image of the event",
    mainImageMobile: "Trending Image Background Mobile",
    mainImageWeb: "Trending Image Background Web",
    images: "Images",
    useImagesModuleEnable: "Add more images to your post",
    useImagesModuleDisable: "Remove option to have more images to your post. ",
    deleteOpeningHoursModuleTitle: "Delete Opening Hours Module",
    deleteOpeningHoursModuleDescription:
      "Do you want to delete the Opening Hours Module?",
    createOpeningHoursModuleTitle: "Add Opening Hours Module",
    createOpeningHoursModuleDescription:
      "Are you sure that you want to add the Opening Hours Module, so that you can personalize the schedule of your listing? ",
    deleteReservationsModuleTitle: "Delete Reservations Module",
    deleteReservationsModuleDescription:
      "Do you want to delete the Reservations Module?",
    createReservationsModuleTitle: "Add Reservations Module",
    createReservationsModuleDescription:
      "Are you sure that you want to add the Reservations Module, so that you can manage bookings at your location? ",
    useOpeningHoursModuleEnable:
      "Add opening hours module. This module let's you personalize the opening hours " +
      "of your listing depending on the day of the week",
    useOpeningHoursModuleDisable: "Remove the opening hours module.",
    useReservationsModuleEnable:
      "Add reservations module. This module allows you to receive bookings at your location .",
    useReservationsModuleDisable: "Disable the reservations module",

    deleteReservationsMenuModuleTitle: "Delete Reservations Menu Module",
    deleteReservationsMenuModuleDescription:
      "Do you want to delete the Reservations Menu Module?",
    createReservationsMenuModuleTitle: "Add Reservations Menu Module",
    createReservationsMenuModuleDescription:
      "Are you sure that you want to add the Reservations Menu Module, so that you can manage menu bookings at your location? ",
    useReservationsMenuModuleEnable:
      "Add Reservations Menu Module. This module allows you to receive bookings with menus at your location. Requires reservations module.",
    useReservationsMenuModuleDisable: "Disable the Reservations Menu Module",

    useTicketsModuleEnable: "Add tickets module to your post",
    useTicketsModuleDisable: "Remove tickets module from your post. ",
    forbiddenDeleteTicketsModuleModalTitle: "Deleting Ticketing Module",
    forbiddenDeleteTicketsModuleModalDesc:
      "This module cannot be deleted because the ticket sale has already started." +
      " To delete this module please contact an Account Manager, because you need to " +
      "refund the sold tickets first.",
    allowedDeleteTicketsModuleModalDesc:
      "Deleting this module will delete all the ticket categories created. " +
      "Continue deleting the module?",
    normalTickets: "Normal Tickets",
    vouchers: "Vouchers",
    earlyBirdTickets: "Early Bird Tickets",
    name: "Name",
    serialNumber: "Serial Number",
    initialTickets: "Initial Tickets",
    remainingTickets: "Remaining Tickets",
    ticketsModule: "Tickets Module",
    displayedPrice: "Displayed Price",
    addTicket: "Add Ticket",
    addVoucher: "Add Voucher",
    eventKey: "SeatsIo Event Key",
    editTicket: {
      ticketName: "Name of the ticket",
      voucherName: "Name of the voucher",
      numberOfAvailableTickets: "Number of available tickets",
      numberOfAvailableVouchers: "Number of available vouchers",
      priceReportDITL: "Price reported to DITL (lei)",
      priceCashout: "Desired revenue (lei)",
      displayedPrice: "Displayed price (lei)",
      commisionNightz: "Nightz Commission (lei)",
      commisionDitl: "Ditl Tax (lei)",
      valueNightzTva: "Nightz VAT Value (lei)",
      valueClientTva: "Company VAT Value (lei)",
      invoiceToNightz: "Value Invoiced to Nightz (lei)",
      ticketDescription: "Description of the ticket",
      addTicket: "Add Ticket",
      addVoucher: "Add Voucher",
      saveTicket: "Save",
      participants: "Participants",
      discountPerPerson:"Discount per person (%)",
      price: "Price per person",
      ticketPromotionType: "Ticket Promotion Type",
      noPromotion: "No Promotion",
      appOnlyPromotion: "App only Promotions",
      tierPromotion: "Tier Promotion",
      normalPrice: "Normal Price",
      editTicket: "Edit Ticket",
      addNewTicket: "Add New Ticket",
      cancel: "Cancel",
      errors: {
        ticketNumberRequired: "Number of tickets required",
        ticketNumberMin: "Total must be greater than 0",
        ticketNumberInteger: "Total must be an integer",
        ticketPriceRequired: "Price per tickets required",
        ticketPriceDitlRequired: "Price per tickets required",
        ticketNameRequired: "Name is required",
        ticketValueNumber: "Value must be a number",
        ticketPromotionParticipantValue: "Participant required",
        ticketPromotionPriceValue: "Price required",
        ticketPromotionParticipantLimit: "Participant must be greater than previous Tier",
        ticketPromotionPriceLimit: "Price must be lower than previous Tier"
      },
      info: {
        ticketPrice: "Please first enter ticket price"
      }
    },
    editPromoCode: {
      addNewPromoCode: "Add New Promo Code",
      editPromoCode: "Edit Promo Code",
      name: "Promo Code",
      percent: "Discount Percent",
      addPromoCode: "Add Promo Code",
    },
    invite: {
      send: "Send",
      title: "Invite your friends",
      name: "Name",
      email: "Email address",
      quantity: "Tickets number",
      success: "Invitations have been sent successfully",
      validation: {
        quantityRequired: "Tickets quantity is required",
        quantityAvailable: "Invalid quantity.",
        quantityDigits: "Quantity must contain onyl digits",
        nameRequired: "Name is required",
      },
    },
    category: "Category",
    insights: "Insights",
    typesOfMusic: "Types of music",
    dateAndTimeInfo: "Date and time information",
    opening: "Start time",
    closing: "End time",
    startDate: "Start time of the event",
    endDate: "End time of the event",
    contactInfo: "Contact information",
    venueInfo: "Informațions about the restaurant",
    facebook: "Your facebook profile",
    instagram: "Your instagram profile",
    tiktok: "Your tiktok profile",
    organizerWebsite: "Link to your website",
    organizerEmail: "Organizer email",
    venueEmail: "Organizer email",
    organizerPhone: "Organizer phone number",
    organizerDisplayName: "Organizer displayed name",
    tags: "Tags",
    ticketTypes: "Ticket Types",
    legalName: "Legal name of the organizer",
    cuiOrganizer: "CUI of the organizer",
    street: "Street and number",
    visibility: "Visibility of the event",
    countyVisibility: "County visibility",
    city: "City",
    county: "County",
    venueName: "Name of the location",
    displayName: "Name of the location",
    status: "Status",
    isMainEvent: "Is Main Event?",
    isPermanent: "Event progress details",
    isPermanentTrue: "Permanent post",
    isPermanentFalse: "One time event",
    mainEventCountyVisibility: "County in which it is Main Event",
    addressLabel: "Event Address",
    googleLocationLink: "Google Maps link to the address",
    downloadData: "Download data",
    createData: "Create Product",
    saveChanges: "Save Changes",
    publishNow: "Publish now",
    publishing: "Publishing",
    isPublishedTrue: "Published",
    isPublishedFalse: "Unpublished",
    pendingButton: "Waiting for approval",
    unPublish: "Unpublish now",
    noTicketsSold: {
      title: "No tickets sold yet!",
      content:
        "Because no tickets were sold for this event, there is no data available to download.",
      understand: "I understand",
    },
    visibilityOptions: {
      web: "Just web",
      app: "Just app",
      web_and_app: "On web and app",
    },
    yourEvents: "Your listings",
    allOfYourEvents: "All of your listings",
    addAPartyNow: "Add a listing now",
    borderColorTicket: "Border color of the ticket",
    trendingBg: "Trending Color",
    borderColorVoucher: "Border color of the voucher",

    tabs: {
      eventDetails: "Listing Details",
      transactions: "Transactions",
      taxReports: "Tax Reports",
      statistics: "Statistics",
      reservationsCalendar: "Reservations Calendar",
      reservationsTable: "Reservations Table",
      tickets: "Tickets",
    },
    companyNotApproved:
      "Your company is not yet verified, please enter your company details to publish the event",
    companyPending:
      "Your company is not yet verified, please wait for your approval",
    cancel: "Cancel",
    rating: "Rating",
    reviewsCount: "Reviews Count",
    priceRangeStart: "Price Range Start",
    priceRangeEnd: "Price Range End",
    program: "Program",
    eventEditor: {
      publishDialogTitle: "Are you sure you want to publish the event?",
      publishDialogContent:
        "You will no longer be able to change tickets after this action. Make sure you report the tickets to DITL. You can find the DITL form",
      publishDialogLink: "here",
      publishDialogAgreeButton: "Publish",
      publishDialogDisagreeButton: "Back",
    },
    strechToSeeMoreColumns:
      "You can see more columns if you use the strech property in the settings",
  },

  deleteEvent: {
    errorName: "You didn't enter the event name correctly",
    dialogTitle: "Delete this event?",
    dialogSubtitle: "Confirm you want to delete this event by typing",
    dialogSubtitleVariant1: "its name",
    dialogSubtitleVariant2: "this",
    dialogSubtitleVariant3:
      "This event can't be deleted because there are tickets have already been sold. " +
      "If you wish to hide this event from customers, please unpublish it and contact" +
      "the administrator if you need further guidance.",
  },

  duplicateListing: {
    dialogTitle: "Do you want to duplicate this listing?",
    dialogSubtitle:
      "A new listing will be created with draft status using the info of this post",
  },

  staticPages: {
    contactUs: {
      title: "Contact Us",
      pageTitle: "Contact details",
      workProgram: "Work program:",
      workProgramDetails:
        "Monday - Friday: 09:00 - 17:00 Weekends and public holidays: Closed",
      address: "Address(For correspondence):",
      addressDetails:
        "Sat Remetea Chioarului, Comuna Remetea Chioarului, Nr. 279, Județ Maramureș; Cod postal: 437230",
      phone: "Phone",
    },
    cookiesPolicy: {
      title: "Cookies Policy",
    },
    termsAndConditions: {
      title: "Terms and Conditions",
    },
    privacyPolicy: {
      title: "Privacy Policy",
    },
  },
  messages: {
    warnUserToSaveChanges:
      "For the photo to be saved as a profile photo, please press the 'Save Changes' button.",
    moduleCreatedSuccessfully: "Module Created Successfully",
    moduleCreatedError: "Error while creating the module",
    moduleDeletedSuccessfully: "Module deleted successfully",
    moduleDeletedError:
      "Error while deleting the module. Please first delete everything regarding the module",
    areaCreateSuccessfully: "Area has been successfully created",
    areaCreateError: "Error while creating the area",
    areaUpdatedSuccessfully: "Area has been successfully updated",
    areaUpdateError: "Error while updating the area",
    areaDeleteSuccessfully: "Area has been deleted successfully",
    areaDeleteError: "Error while deleting area.",
    spaceDeleteSuccessfully: "Table has been deleted successfully",
    spaceDeleteError: "Error while deleting table.",
    spaceCreateSuccessfully: "Table has been created successfully",
    spaceCreateError: "Error while creating table.",
    spaceUpdateSuccessfully: "Table has been updated successfully",
    spaceUpdateError: "Error while updating table.",
    menuDeleteSuccessfully: "Menu has been deleted successfully",
    menuDeleteError: "Error while deleting menu.",
    menuCreateSuccessfully: "Menu has been created successfully",
    menuCreateError: "Error while creating menu.",
    menuUpdateSuccessfully: "Menu has been updated successfully",
    menuUpdateError: "Error while updating menu.",
  },
  companyRegisterForm: {
    title: "Complete the form to create your account",
    skipForNow: "Skip for now",
    saveChangesAndCompleteLater: "Save changes and complete later",
    saveChanges: "Save changes",
    modifyNow: "Make changes now",
    messageModalHasErrors:
      "Your info has been saved, but the form has not been submitted because some fields are incomplete or incorrect and require your attention.",
    titleModalHasErrors: "Form is incorrect or incomplete",
    messageModalHasNoErrors:
      "Hooray! The form has been submitted successfully. A member of our team will be in touch with you shortly.",
    titleModalHasNoErrors: "Form sent",
    fiscalInformation: "Fiscal Information",
    companyRegistrationNumber: "Company Registration Number",
    companyName: "Company Name",
    companyType: "Company Type",
    companyRegistrationCountry: "Company Registration Country",
    euVatNumber: "EU-VAT Number",
    tradeRegistryNumber: "Trade Registry Number",
    invoiceSeries: "Invoicing series",
    invoiceNumber: "Invoicing number",
    europeanUniqueIdentifier: "European Unique Identifier",
    vatObligations: "VAT Obligations",
    vatObligationsOpt1: "Yes",
    vatObligationsOpt2: "No",
    companyLegalAddress: "Company Legal Address",
    country: "Country / State",
    city: "City",
    county: "County",
    address: "Address",
    postalCode: "Postal Code",
    legalRepresentative: "Legal Representative",
    firstName: "First Name",
    lastName: "Last Name",
    telephone: "Telephone",
    emailAddress: "Email Address",
    bankDetails: "Bank Details",
    iban: "IBAN",
    bankName: "Bank Name",
    swift: "SWIFT",
    currency: "Currency",
    documents: "Documents",
    uploadTheFollowingRequiredDocuments:
      "Upload the following documents needed for the selling of the tickets",
    uploadTheFollowingOptionalDocuments:
      "Upload the following documents needed for the automatic report at DITL (optional)",
    optionalDocumentsDetails:
      "This service is intended for event organizers and is provided by Nightz, free of charge. " +
      "This service can be activated/deactivated from the event publishing form.",
    getTemplate: "Get Template",
    name: "Name",
    size: "Size",
    type: "Type",
    modified: "Modified",
    actions: "Actions",
    finalStep: "Final Step",
    deleteAll: "Delete All",
    uploadFiles: "Upload Files",
    uploadFile: "Upload",
    back: "Back",
    next: "Next",
    finalStep1: "Hooray!",
    finalStep2: "You completed the form!",
    finalStep3:
      "Take one more look to make sure all the information is correct.",
    finalStep4:
      "We will review your application shortly and notify you when your account will be activated and you will be able to publish events.",
    sendApplication: "Send Application",
    okButton: "Ok",
    contactDetails: "Contact Details",
    delete: "Delete",
    view: "View",
    saveDataAndSkipForNow: "The data has been saved.",
    uploadedDocuments: "Uploaded Documents",
    noDocumentsUploaded: "No documents uploaded",
    accordingToOriginalText:
      "All the copies must be signed and they also must contain the text: 'Conform cu originalul'. ",
  },
  eventTaxReports: {
    ticketRegistration: "Ticket Registration",
    neccesaryDocuments: "Necessary Documents",
    dowloadAll: "Download All",
    ditlRegistration: "DITL Registration",
    taxReporting: "Tax Reporting",
    attention: "Attention!",
    attentionDescription:
      "Companies wishing to have reporting done by Nightz should upload the following documents in the `My Business` section:",
    availableBelow: "available below",
    autogenerated: "autogenerated",
    goToMyBusiness: "Go to My Business",
    justNGO: "(Just NGO)",
    downloadTemplate: "Download a template document",
    viewDocument: "View the document",
  },

  eventStatistics: {
    dateSelectorLabelOpt1: "7 dayz",
    dateSelectorLabelOpt2: "14 days",
    dateSelectorLabelOpt3: "30 days",
    dateSelectorLabelOpt4: "Custom",
    salesOverview: "Sales Overview",
    revenueOverview: "Revenue Overview",
    totalRevenue: "Total Revenue",
    totalRevenueTickets: "Total Revenue Tickets",
    totalSoldTickets: "Total Sold Tickets",
    revenueByTicketType: "Revenue by Ticket Type",
    soldTicketsByTicketType: "No. of Sold Tickets by Ticket Type",
  },

  invoicesTable: {
    tableTitle: "Invoices",
    invoiceTotal: "Total",
    invoicePaid: "Paid",
    invoiceUnpaid: "Unpaid",
    changeStatus: "Change Status",
    exportPdf: "Export pdf",
    exportXml: "Export xml",
    deleteInvoices: "Delete Invoices",
    documentType: "Document Type",
    company: "Company",
    documentNumberInputPlaceholder: "Search by Document Number",
    companyNameInputPlaceholder: "Search by Company Name",
    eventInputPlaceholder: "Search by Event",
    amount: "Amount",
    type: "Type",
    created: "Created",
    status: "Status",
    name: "Name",
    noInvoices: "No invoices yet, try other filters",
    changeStatusTo: "Change status to",
    invoiced: "Invoiced",
    paid: "Paid",
    event: "Event",
  },

  transactionsTable: {
    tableTitle: "Transactions",
    export: "Export",
    createInvoice: "Create Invoice",
    searchIdAndSeries: "Search by Order ID or Ticket Series",
    searchNameMailPhone: "Search by Buyer Name, Email or Phone",
    orderId: "Order ID",
    clientOrderStatus: "Client Order Status",
    purchaseDate: "Purchase Date",
    name: "Name",
    phone: "Phone",
    email: "Email",
    total: "Total",
    event: "Event",
    company: "Company",
    companyInvoiceStatus: "Company Invoice Status",
    orderStatus: "Order Status",
    genericError: "A problem occurred! Please try again later.",
  },

  invoicesDetails: {
    titlePage: "Invoice Details",
    currentFilters: "Current Filters",
    eventId: "Event ID",
    createdFrom: "Created from",
    createdTo: "Created to",
    companyId: "Company ID",
    noDraftsData: "No draft data",
  },

  ticketTable: {
    name: "Name",
    serialNumber: "Serial Number",
    initialTickets: "Initial Tickets",
    initialVouchers: "Initial Vouchers",
    remainingTickets: "Remaining Tickets",
    remainingVouchers: "Remaining Vouchers",
    displayedPrice: "Displayed Price (lei)",
    visibility: "Visibility",
    genericError: "A problem occurred! Please try again later.",
    ticketTableNoDataTitle: "No tickets yet",
    ticketTableNoDataDescription:
      "Once a ticket is bought, it will appear here",
    excelOptions: {
      ticketsOnly: "All unfiltered Tickets",
      filteredTickets: "Filtered Tickets",
    },
  },
  documents: {
    registrationCertificatefromNationalTradeRegistry:
      "Unique Registration Certificate / Fiscal Registration Certificate",
    contractNightzB2B: "Contract Nightz B2B",
    termsAndConditionsForPartners: "Terms and Conditions for Partners",
    decontForm: "Decont Form",
    articleOfIncorporation: "Article Of Incorporation",
    ticketRegistrationForm: "Ticket Registration Form",
    mandateForNightz: "Mandate for Nightz",
    idCard: "ID Card",
    articleOfAssociation: "Article of Association",
    unsoldTicketsForm: "Unsold Tickets Form",
  },
  deletedMessages: {
    deletedName: "Deleted Name",
    deletedEmail: "Deleted Email",
    deletedPhone: "Deleted Phone",
    deletedCompany: "Deleted Company",
    deletedAccount: "Deleted Account",
    deletedCui: "Deleted Cui",
    deletedStatus: "Deleted Status",
  },
  apiErrorCodes: {
    NIGHTZ_ERROR_SEATSIO_BOOKING_FAILED:
      "The seats are no longer available. Please choose different seats! This happens if the holding time for the seats expired and someone else bought them. If the blocked seats have not been bought, they become free again in 15 min",
    NIGHTZ_ERROR_RESERVATION_FAILED:
      "Checkout fails because the configured reservation is not available anymore. Consider clearing you cart and try a new booking configuration",
  },
  resourceNotAvailable: {
    title: "Resource not available",
    description: "Sorry, we could not load the resource you’re looking for. Perhaps you are not not logged in with the correct user",
  },
};

export default en;

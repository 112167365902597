import "./bookingStyle.css";
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useLocales } from "../../../../locales";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormProvider from "../../../../components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, SecondaryButton } from "../listing-details/listingButtons";
import { useAuthContext } from "src/hooks/useAuthContext";
import { useMutationWithAuth, useQueryWithAuth } from "src/graphql/instances";
import { CLEAR_CART_ITEMS, UPDATE_SHADOW_ACCOUNT_INFO } from "src/graphql/mutations";
import {
  CHECK_AVAILABILITY_BOOKING,
  CREATE_BOOKING_HOLDING_FEE_USER,
  CREATE_BOOKING_USER,
} from "src/graphql/mutations/bookings";
import getRoles from "../../../../utils/getRoles";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import BookingCustomerDataCard from "../listing-details/BookingCustomerDataCard";
import BookingDataCard from "./BookingDataCard";
import { UPDATE_SHADOW_PROFILE } from "src/graphql/mutations/updateProfile";
import { RESERVATION_CONSTANTS } from "src/config/constants/reservation-constants";
import useResponsive from "../../../../hooks/useResponsive";
import { isEventNonPermanentAndNoOpeningHours } from "../../../../utils/bookingModule";
import { EVENT_TYPES_CONSTANTS } from "../../../../config/constants/event-types-constants";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../routes/paths";
import { GET_CART, GET_CART_QUANTITY, GET_EVENT } from "../../../../graphql/queries";
import DeleteForeignTicketsModal from "../listing-details/DeleteForeignTicketsModal";
import { useParams } from "react-router";
import EventImageLayout from "src/layouts/event";
import PropTypes from "prop-types";
import Iconify from "../../../../components/iconify";

const TIMEOUT_AFTER_SUCCESS = 1000;

const FIRST_STEP_FIELDS_FULL = ["guests", "date", "length", "area", "time"];
const FIRST_STEP_FIELDS_NON_PERMANENT_EVENT = ["guests", "area"];
const SECOND_STEP_FIELDS = ["fullName", "phoneNumber", "email"];

BookingPage.propTypes = {
  eventId: PropTypes.string,
  bookingToEdit: PropTypes.object,
  backHandler: PropTypes.func,
  removeHandler: PropTypes.func,
  doneBookingHandler: PropTypes.func,
};

export default function BookingPage({ eventId, bookingToEdit, backHandler, removeHandler, doneBookingHandler }) {
  const { eventId: eventIdParam } = useParams();
  eventId = eventId || eventIdParam;

  const token = localStorage.getItem("accessToken");
  const { getNewShadow, user, isShadow, refresh } = useAuthContext();
  const client = useApolloClient();
  const navigate = useNavigate();
  const { data: cartData } = useQueryWithAuth(GET_CART, {
    fetchPolicy: "no-cache",
  });
  const [clearCartItems] = useMutationWithAuth(CLEAR_CART_ITEMS);

  const [updateShadowAccountInfo] = useMutationWithAuth(
    UPDATE_SHADOW_ACCOUNT_INFO
  );
  const [updateShadowAccountProfile] = useMutationWithAuth(
    UPDATE_SHADOW_PROFILE
  );
  const [createBooking] = useMutationWithAuth(CREATE_BOOKING_USER);
  const [createBookingHoldingFee] = useMutationWithAuth(
    CREATE_BOOKING_HOLDING_FEE_USER
  );
  const [checkAvailabilityBooking, { loading: loadingCheckAvailability }] =
    useLazyQuery(CHECK_AVAILABILITY_BOOKING, {
      context: { headers: { Authorization: token } },
      fetchPolicy: "no-cache",
    });
  const [getEvent] = useLazyQuery(GET_EVENT, {
    context: {
      headers: {
        "x-nightz-standard-behaviour": "true",
        "x-nightz-platform": "web",
        Authorization: token,
      },
    },
    variables: { eventId },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [eventData, setEventData] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableHours, setAvailableHours] = useState([]);
  const [availableAreas, setAvailableAreas] = useState([]);
  const [availableLength, setAvailableLength] = useState([]);
  const [bookingCardVariant, setBookingCardVariant] = useState(null);
  const [hasOtherEventItemsInCart, setHasOtherEventItemsInCart] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [clearCartModalOpen, setClearCartModalOpen] = useState(false);
  const [holdingFee, setHoldingFee] = useState(0);

  const isMobile = useResponsive("down", "sm");
  const theme = useTheme();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const BookingSchema = Yup.object().shape({
    guests: Yup.number()
      .required(translate("bookings.clientBookingValidation.guestsRequired"))
      .nullable(),
    area: Yup.string()
      .required(translate("bookings.clientBookingValidation.areaRequired"))
      .nullable(),
    date: Yup.date()
      .required(translate("bookings.clientBookingValidation.dateRequired"))
      .typeError(translate("bookings.clientBookingValidation.dateRequired")),
    time: Yup.string()
      .required(translate("bookings.clientBookingValidation.timeRequired"))
      .nullable(),
    length: Yup.string()
      .required(translate("bookings.clientBookingValidation.lengthRequired"))
      .nullable(),
    fullName: Yup.string().required(
      translate("bookings.formValidation.fullNameRequired")
    ),
    phoneNumber: Yup.string().required(
      translate("bookings.formValidation.phoneNumber")
    ),
    email: Yup.string()
      .required(translate("bookings.formValidation.emailRequired"))
      .email(translate("bookings.formValidation.emailValid")),
  });

  const defaultValues = {
    guests: bookingToEdit?.numberOfGuests || 1,
    date: bookingToEdit?.bookedDate || null,
    area: bookingToEdit?.areaId || null,
    time: null,
    fullName: !isShadow
      ? `${user?.firstName || ""} ${user?.lastName || ""}`
      : "",
    phoneNumber: !isShadow ? user?.phone || "" : "",
    email: !isShadow ? user?.email || "" : "",
    length: null,
  };

  const methods = useForm({
    resolver: yupResolver(BookingSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const nrBookingSteps = holdingFee ? 1 : 2;

  const loadAvailability = async (activeBookingStep) => {
    if (!eventData) return;

    const isFullFlow = bookingCardVariant === RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL;
    const steps = isFullFlow ? FIRST_STEP_FIELDS_FULL : FIRST_STEP_FIELDS_NON_PERMANENT_EVENT;
    if (!activeBookingStep) {
      activeBookingStep = steps[0];
    }
    const activeBookingStepIndex = steps.indexOf(activeBookingStep);
    const { guests, date, length, area, time } = getValues();
    const res = await checkAvailabilityBooking({
      variables: {
        showFirstAvailable: true,
        bookingModuleId: eventData.booking.id,
        numberOfGuests: guests,
        bookedDate: isFullFlow && activeBookingStepIndex >= 1 ? date : undefined,
        duration: isFullFlow && activeBookingStepIndex >= 2 ? length : undefined,
        areaId: (isFullFlow && activeBookingStepIndex >= 3) ||
          (!isFullFlow && (activeBookingStepIndex >= 1 || (!!bookingToEdit && bookingToEdit.areaId === area))) ?
            area : undefined,
        bookedHour: isFullFlow && activeBookingStepIndex >= 4 ? time : undefined,
      },
    });
    const data = res?.data?.checkAvailabilityBookingmodule;
    const resDates = data?.dates?.filter(it => it.status === "available");
    const resDurations = data?.durations || [];
    const resAreas = data?.areas?.map(it => ({ label: it.name, value: it.id })) || [];
    const resTimes = data?.bookedHours || [];
    const resFee = data?.holdingFee || 0;

    setAvailableDates(resDates);
    setAvailableLength(resDurations);
    setAvailableAreas(resAreas);
    setAvailableHours(resTimes);
    setHoldingFee(resFee);

    if (activeBookingStepIndex < 1) setValue("date", resDates?.[0]?.name);
    if (activeBookingStepIndex < 2)  setValue("length", resDurations?.[0]);
    if (activeBookingStepIndex < 3 && (isFullFlow || !resAreas.find(it => it.value === area)))
      setValue("area", resAreas?.[0]?.value);
    if (activeBookingStepIndex < 4)  setValue("time", resTimes?.[0]);
  }

  useEffect(() => {
    (async () => {
      const eventData = await getEvent();
      setEventData(eventData.data.event);
    })();
  }, []);
  
  useEffect(() => {
    if (cartData) {
      checkClearCartModalStatus();
    }
  }, [cartData]);

  useEffect(() => {
    const combination = isEventNonPermanentAndNoOpeningHours(
      eventData?.general.openingHours,
      eventData?.general.startDate,
      eventData?.general.endDate,
      eventData?.general.isPermanent
    );
    if (
      combination ===
      EVENT_TYPES_CONSTANTS.COMBINATIONS.NO_OPENING_HOURS_AND_NON_PERMANENT
    ) {
      setBookingCardVariant(
        RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.NON_PERMANENT_EVENT
      );
    } else {
      setBookingCardVariant(RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL);
    }
    loadAvailability();
  }, [eventData]);

  const deleteForeignTicketsAndCreateBooking = async () => {
    try {
      await clearCartItems();

      client.refetchQueries({
        include: [GET_CART_QUANTITY],
      });
      await handleCreateBooking(true);
    } catch (e) {
      console.error(e);
    }
  };

  const checkClearCartModalStatus = () => {
    const itemsToOtherEvent = cartData?.cart?.items?.filter(it =>
      (it.ticketType && it.ticketType?.eventId !== eventId) ||
      (it.bookingModule && it.bookingModule.event.general.id !== eventId)
    );

    setHasOtherEventItemsInCart(!!itemsToOtherEvent?.length)
  };

  const validatePartially = async (fields) => {
    const validatorArray = [];
    fields.forEach((field) => {
      validatorArray.push(trigger(field));
    });

    const isValid = await Promise.all(validatorArray);
    return !isValid.includes(false);
  };

  const handleStepNext = async () => {
    if (activeStep === 0) {
      const isValid = await validatePartially(
        bookingCardVariant === RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL
          ? FIRST_STEP_FIELDS_FULL
          : FIRST_STEP_FIELDS_NON_PERMANENT_EVENT
      );
      if (isValid) {
        if (doneBookingHandler) {
          const values = getValues();
          return doneBookingHandler({
            bookingModuleId: eventData.booking.id,
            numberOfGuests: values.guests,
            bookedDate: values.date,
            duration: values.length,
            areaId: values.area,
            bookedHour: values.time,
            holdingFee,
          });
        }
        if (nrBookingSteps > 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          await handleCreateBooking();
        }
      }
    } else {
      const isValid = await validatePartially(SECOND_STEP_FIELDS);
      if (isValid) {
        await handleCreateBooking();
      }
    }
  };

  const handleStepBack = () => {
    if (backHandler) {
      return backHandler();
    }
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      navigate(-1);
    }
  };

  const findLabelById = (id, array) => {
    const result = array.find((item) => item.value === id);
    return result ? result.label : null;
  };

  const getHeader = (title, icon, error) => (
    <Box display="flex" flexDirection="column" sx={{ mt: 2.7, mb: 0.9 }}>
      <Box display="flex" alignItems="center">
        {!!icon && (typeof icon === 'string' ? <Iconify icon={icon} sx={{ marginRight: 1 }} /> : icon)}
        <Typography variant="subtitle1">{title}</Typography>
      </Box>
      <Box>
        {!!error?.message && (
          <Typography variant="subtitle1" color={theme.palette.error.main}>
            {error.message}
          </Typography>
        )}
      </Box>
    </Box>
  );

  const handleCreateBooking = async (skipTicketCheck = false) => {
    if (getRoles().Company.includes(user.role)) {
      enqueueSnackbar(translate("bookings.errorNoPermissionCompany"), {
        variant: "error",
        autoHideDuration: 5000,
      });
    }
    if (getRoles().People.includes(user.role)) {
      try {
        const values = getValues();

        if (isShadow && !holdingFee) {
          const updateResult = await updateShadowAccountInfo({
            variables: {
              email: values.email,
              phone: values.phoneNumber,
            },
          });
          const updateProfile = await updateShadowAccountProfile({
            variables: {
              firstName: values.fullName.split(" ")[0] ?? "",
              lastName: values.fullName.split(" ")[1] ?? "",
              phone: values.phoneNumber,
            },
          });

          if (!updateResult.data && !updateProfile.data) {
            enqueueSnackbar(translate("bookings.error"), {
              variant: "error",
              autoHideDuration: 5000,
            });
            return;
          }
          await refresh();
        }
        let newBookingData = {};
        if (
          bookingCardVariant ===
          RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.FULL
        ) {
          newBookingData = {
            bookedDate: values.date,
            bookedHour: values.time,
            numberOfGuests: values.guests,
            duration: values.length,
            bookingModuleId: eventData.booking.id,
            areaId: values.area,
          };
        }
        if (
          bookingCardVariant ===
          RESERVATION_CONSTANTS.BOOKING_CARD_VARIANTS.NON_PERMANENT_EVENT
        ) {
          newBookingData = {
            numberOfGuests: values.guests,
            bookingModuleId: eventData.booking.id,
            areaId: values.area,
          };
        }
        let bookingResult = {};
        if (holdingFee === 0) {
          bookingResult = await createBooking({
            variables: newBookingData,
          });
        }
        if (holdingFee > 0) {
          if (hasOtherEventItemsInCart && !skipTicketCheck) {
            setClearCartModalOpen(true);
            return;
          }

          bookingResult = await createBookingHoldingFee({ variables: { metadata: { booking: { ...newBookingData, holdingFee } } } });
        }
        if (
          typeof bookingResult?.data?.createBookingReservation === "string" ||
          typeof bookingResult?.data?.createCartBookingHoldingFee?.id ===
            "string"
        ) {
          if (isShadow && holdingFee === 0) {
            await getNewShadow();
          }
          if (holdingFee === 0) {
            enqueueSnackbar(translate("bookings.successReservationMade"), {
              variant: "success",
              autoHideDuration: 5000,
            });
          }

          setTimeout(() => {
            if (holdingFee === 0) {
              navigate(-1);
            }
            reset();
            setCheckBox(false);
          }, [TIMEOUT_AFTER_SUCCESS]);

          if (holdingFee > 0) {
            navigate(PATHS.listings.checkout);
          }
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar(translate("bookings.error"), {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    }
  };

  const steps = [
    {
      label: "booking-data",
      component: (
        <BookingDataCard
          eventGeneralData={eventData?.general}
          watch={watch}
          setValue={setValue}
          trigger={trigger}
          getHeader={getHeader}
          errors={errors}
          findLabelById={findLabelById}
          availableDates={availableDates}
          availableLength={availableLength}
          availableAreas={availableAreas}
          availableHours={availableHours}
          bookingCardVariant={bookingCardVariant}
          loadingCheckAvailability={loadingCheckAvailability}
          isInIframe={false}
          holdingFee={holdingFee}
          loadAvailability={loadAvailability}
        />
      ),
    },
  ];
  if (!holdingFee) {
    steps.push({
      label: "customer-data",
      component: (
        <BookingCustomerDataCard
          getHeader={getHeader}
          translate={translate}
          checkboxValue={checkBox}
          handleCheckbox={() => setCheckBox(!checkBox)}
          isInIframe={false}
        />
      ),
    });
  }

  if (!eventData) {
    return "Loading";
  }
  
  return (
    <EventImageLayout
      title={`Booking ${eventData?.general?.name}`}
      image={eventData?.general?.images[0]}
      fields={eventData?.general?.fields}
      useFullHeight={true}
    >
      <Box
        onClick={handleStepBack}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Iconify icon="eva:chevron-left-fill" width={40} sx={{ zIndex: 2 }}/>
        <Typography variant="h5" color={theme.palette.text.primary} sx={{ zIndex: 2, mx: "auto" }}>
          {translate("bookings.bookingDetails.title")}
        </Typography>
      </Box>

      <Card
        sx={{
          mt: 2,
          backgroundColor: "transparent",
          marginBottom: 1,
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            "&.MuiCardContent-root": { padding: isMobile ? 1 : 3 },
          }}
        >
          <FormProvider methods={methods}>
            {steps[activeStep].component}
          </FormProvider>
        </CardContent>
      </Card>

      <Box sx={{ marginBottom: 3, textAlign: "right" }}>
        {!bookingToEdit?.numberOfGuests &&(
          <SecondaryButton
            variant="outlined" 
            color="inherit"
            size="large"
            onClick={handleStepBack}
            sx={{ backgroundColor: "transparent" }}
          >
            {translate("bookings.back")}
          </SecondaryButton>
        )}
        {!!bookingToEdit?.numberOfGuests &&(
          <SecondaryButton
            variant="outlined" 
            color="inherit"
            size="large"
            onClick={removeHandler}
            sx={{ backgroundColor: "transparent" }}
          >
            {translate("bookings.remove")}
          </SecondaryButton>
        )}
        <PrimaryButton
          sx={{ marginLeft: 3 }}
          size="large"
          variant="contained"
          onClick={handleStepNext}
          disabled={!checkBox && activeStep === 1}
        >
          {translate("bookings.continue")}
        </PrimaryButton>
      </Box>

      <DeleteForeignTicketsModal
        modalOpen={clearCartModalOpen}
        setModalOpen={setClearCartModalOpen}
        confirmDeleteCallback={deleteForeignTicketsAndCreateBooking}
      />
    </EventImageLayout>
  );
}

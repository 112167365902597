import { gql } from "@apollo/client";

const GET_COMPANY_DOCUMENTS = gql`
  query Company($companyId: ID!) {
    company(id: $companyId) {
      companyInfo {
        documents {
          title
          url
        }
      }
    }
  }
`;
const GET_COMPANY_DITL_INFO = gql`
  query Company($companyId: ID!) {
    company(id: $companyId) {
      companyInfo {
        address {
          city
          county
          street
          venueName
        }
        legalRepresentativeEmail
        companyName
        uniqueRegistrationCode
        tradeRegistryNumber
      }
    }
  }
`;

const GET_COMPANY_STATUS = gql(`
query Company($companyId: ID!) {
  company(id: $companyId) {
    approval
  }
}
`);

export { GET_COMPANY_DOCUMENTS, GET_COMPANY_DITL_INFO, GET_COMPANY_STATUS };

import { Box, Link, Button, Divider, Typography, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// routes
import { PATHS } from "src/routes/paths";
// components
import Iconify from "src/components/iconify/Iconify";
import { OrderCompleteIllustration } from "src/assets/illustrations";
import { useLocales } from "src/locales";

const CheckoutSuccess = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();

  const handleContinueShopping = () => {
    navigate(PATHS.listings.list);
  };

  return (
    <Box sx={{ p: 4, maxWidth: 480, margin: "auto" }}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h4" paragraph>
          {translate("checkout.success.title")}
        </Typography>

        <OrderCompleteIllustration sx={{ height: 260, my: 10 }} />

        <Typography align="center" paragraph>
          {translate("checkout.success.order1")}{" "}
          <Link href="#">{location?.state?.receivedId}</Link>{" "}
          {translate("checkout.success.order2")}
        </Typography>

        <Typography align="center" sx={{ color: "text.secondary" }}>
          {translate("checkout.success.mail")}
          <br /> <br />
          {translate("checkout.success.problems")}
        </Typography>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Stack>
        <Button
          color="inherit"
          onClick={handleContinueShopping}
          startIcon={<Iconify icon={"eva:arrow-ios-back-fill"} />}
        >
          {translate("checkout.continueShopping")}
        </Button>
      </Stack>
    </Box>
  );
};

export default CheckoutSuccess;

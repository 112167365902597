import React from "react";
import { Stack, Typography, Rating } from "@mui/material";

const VenueRating = ({ rating, reviewsCount, other }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="top" {...other}>
      <Typography color="gray" fontSize={14}>
        {rating}
      </Typography>
      <Rating precision={0.5} size="small" value={rating} readOnly />
      <Typography color="gray" fontSize={14}>{`(${reviewsCount})`}</Typography>
    </Stack>
  );
};

export default VenueRating;
